.container {
 p {
   margin-top: 0;
 }
}

.userDetailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
  font-size: 16px;
}

.sdgContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}