.select {
  height: 2.5em;
  display: block;

  > div {
    height: inherit !important;
    text-transform: initial;
    letter-spacing: initial;
    font-size: .75rem;
    margin-top: .5rem;
  }
}