@use '../Utilities/Styling/mixins';
@use '../Utilities/Styling/colors';
@use 'sass:color';

.container {
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;

  label{
    background-color: transparent !important;
  }

  input[type=file] {
    height: 1px;
    width: 1px;
    padding: 0;
    margin: 0;
    pointer-events: none;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute !important;
    white-space: nowrap;
  }
}
.uploadedImg {
  width: 350px;
  height: 250px;
  margin-left: 10px;

}
.addImg{
  width: 50px;
  height: 50px;
  cursor: pointer;
}