.causeArea{
  width: 100%;
  label{
    margin-bottom: 0;
  }
}
.ant-select .ant-select-single .ant-select-show-arrow{
  width: 100% !important;
}

.select {
  height: 2.5em;
  display: block;
  width: 100%;
  > div {
    height: inherit !important;
    text-transform: initial;
    letter-spacing: initial;
    font-size: .75rem;
    margin-top: .5rem;
  }
}