@use './colors';
@use './values';

@mixin defaultItemBorder {
  border: 1px solid colors.$med-gray;
}

@mixin defaultItemContainer {
  background-color: colors.$white;
  border: 1px solid ;
  @include defaultItemBorder;
  @include values.defaultRadius;
}

@mixin smallButton($bgcolor: colors.$red, $color: colors.$white) {
  background-color: $bgcolor;
  font-size: .90rem;
  padding: .5em 2em;
  font-weight: 300;
  color: $color;
  text-align: center;
  @include colors.buttonShadow($bgcolor);
  @include values.defaultRadius;
}