@use 'sass:color';
@use '../../../Utilities/Styling/colors';
@use '../../../Utilities/Styling/values';
@use '../../../Utilities/Styling/mixins';

.volunteer{
  width: 100%;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.container {
  margin-bottom: values.$main-padding-bottom;

  h1 input:disabled { -webkit-text-fill-color: colors.$red }

  h3 {
    margin-bottom: .25rem;
    &:not(:first-child) { margin-top: 1em }

    + hr { margin: .25rem 0 }
  }


  input:disabled {
    opacity: 1;
    -webkit-text-fill-color: colors.$black;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] { -moz-appearance: textfield }
  }
}

.imageAndOverview {
  background-color: color.adjust(colors.$med-gray, $lightness: 3%);
  padding: 1.5rem 0;
  margin-bottom: 2rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.imageArea {
  height: fit-content;
  width: 533px;
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: -4rem;
    width: 3rem;
    height: 3rem;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Crect class='a' width='24' height='24'/%3E%3Cpath class='b' d='M3,4V1H5V4H8V6H5V9H3V6H0V4Zm3,6V7H9V4h7l1.83,2H21a2,2,0,0,1,2,2V20a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V10Zm7,9a5,5,0,1,0-5-5A5,5,0,0,0,13,19ZM9.8,14A3.2,3.2,0,1,0,13,10.8,3.2,3.2,0,0,0,9.8,14Z'/%3E%3C/svg%3E") no-repeat;
    background-position: center;
    background-size: 30px;
    background-color: colors.$red;
    border-radius: 50%;
    cursor: pointer;
    @include colors.buttonShadow;

    input { display: none }
  }

  img {
    height: auto;
    width: 100%;
    background-color: white;
    @include values.defaultRadius;
  }
}

.overviewArea {
  text-align: center;
  padding: 0 5rem;

  .volunteers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div {
      flex: 1 0;
      max-width: 10rem;
      background-color: color.adjust(colors.$mint, $saturation: 30%);
      color: colors.$red;
      font-weight: 600;
      margin: .5rem;
      padding: 1.25rem 0;
      @include values.defaultRadius;

      h5 { margin: 0 0 .25rem }
    }
  }

  p { font-size: .75rem }

  button {
    display: block;
    margin: 0 auto;
  }
}

.eventTitleArea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;

  h1 { flex: 1 }

  button {
    color: colors.$red;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .2em;
    background: none;
    box-shadow: none;
    margin-left: 3rem;
    height: auto;
  }
}

:global(.h5-label.horizontal) {
  grid-template-columns: 8.5rem 1fr;
}

:global(.h5-label):not(:global(.horizontal)):disabled {
  margin-top: 1.125rem;
  margin-bottom: .5rem;
}

.sdgSelect {
  font-size: 1rem;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: initial;
  display: block;
  margin-top: 1rem;
width: 100%;
  &:disabled { cursor: default }
}

.sdgCell {
  width: 33% !important;
  margin-top: .75rem;
}

