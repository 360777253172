.container {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 3.25rem;

  h3 {
    margin-bottom: .5em;
    &:not(:first-child) { margin-top: 1em }
  }

  .addressContainer {
    margin-bottom: 2rem;
  }

  :global(.h5-label.horizontal) {
    grid-template-columns: 8.5rem 1fr;
  }

  :global(.h5-label):not(:global(.horizontal)):disabled, h5 {
    margin-top: 1.125rem;
  }

}