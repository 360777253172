@use 'sass:color';
@use './values.scss';

// Base Colors
$red: #D60021;
$mint: #CFEFEE;
$black: #313131;
$gray: #F8F8F8;
$white: white;
$orange: #FFA500;
$darkGray: #333;
$green: #00FF00;
$yellow: #FFFF00;
$blue: #0000FF;
$lightGray: #F5F5F5;

// Variations
$dark-red: #980012;
$dark-pink: #EFD6D9;
$med-gray: #E8E8E8;

// Gradients
@mixin background-red-gradient($degree: 90) {
  background: linear-gradient(#{$degree}deg, $red 0%, $dark-red 100%);;
}

// Shadows
@mixin buttonShadow($color: $red) {
  box-shadow: 3px 4px 20px color.adjust($color, $alpha: -.7);
}