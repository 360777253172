@use '../Utilities/Styling/values';
@use '../Utilities/Styling/colors';
@use '../Utilities/Styling/mixins';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  margin-bottom: .5rem;
  @include mixins.defaultItemContainer;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:not(:first-of-type) { margin-right: 1rem }
    &:first-of-type { flex: 30% }
    &:nth-of-type(2) { flex: 70% }
    &:last-of-type {
      flex: 0 0 1.5rem;
      align-items: center;
    }

    h5 {
       margin: 0 0 .25em;
       color: colors.$red;
       font-weight: 600;
       font-size: 10px;
       margin-bottom: 3px;
       line-height: 16px;
       letter-spacing: 1px;
      }
  }

  a {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @include mixins.smallButton;
    align-self: center;
  }
}