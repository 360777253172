@use '../../Utilities/Styling/colors';

.createItemButton {
  // Styling
  color: colors.$red;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: .2em;
  padding: 0;
  text-decoration: none;

  // Positioning
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  span {
    font-weight: 700;
    font-size: 1em;
    margin-right: .5em;
  }

}