@use './Utilities/Styling/values';
@use './Utilities/Styling/colors';
@use './Utilities/Styling/mixins';
@use 'sass:color';

body {
  margin: 0;
  font-family: values.$font-exo;
  color: colors.$black;
  * { font-family: values.$font-exo }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
 background-color: colors.$gray;
}

main {
  padding-top: values.$main-padding-top;
  padding-bottom: values.$main-padding-bottom;
  flex: 1;
}

main, .main-size {
  @include values.mainSize
}

header {
  position: relative;
  margin-bottom: 2rem;
}

h1, h2, h3, h4 {
  margin: 0;
}

h1 {
  margin: 0;
  font-size: 2rem;
  color: colors.$red;
  font-weight: 700;
}

h4 { font-size: 1.25rem }

h5, .h5-label {
  font-size: .625rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .1em;
  color: colors.$red;
  margin: 1em 0;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

label {
  display: block;
  font-size: 1rem;

  &:not(:last-child) { margin-bottom: 2em }
}

input, select {
  box-sizing: border-box;
  font-size: inherit;
  height: 2.75em;
  padding: 0 1em;
  margin-top: .5em;
  display: block;
  width: 100%;
  box-shadow: none;
  color: inherit;
  font-weight: inherit;
  @include mixins.defaultItemBorder;
  @include values.defaultRadius;

  &::placeholder {
    opacity: .5;
  }
}

h1 input {
  margin-top: 0;
  &:disabled {
    background-color: transparent;
    border: none;
    padding: 0;
    height: unset;
  }
}

.h5-label {

  &.horizontal {
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    margin: 0;
  }

  &:not(.horizontal) {
    margin-top: 1.5rem;
    input, select, textarea {
      margin-top: 1rem;
    }
  }

  input, select, textarea {
    font-size: 1rem;
    color: colors.$black;
    font-weight: 400;

    option { padding-left: 0 }
    &:disabled {
      padding: .5em 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      resize: none;
      height: 2em;
      background-color: transparent;
      border: none;
      margin-top: 0;
    }
  }
}

textarea {
  box-sizing: border-box;
  font-size: inherit;
  padding: 1em;
  margin-top: .5em;
  display: block;
  width: 100%;
  box-shadow: none;
  @include mixins.defaultItemBorder;
  @include values.defaultRadius;

  &::placeholder {
     opacity: .5;
  }
}

textarea {
  resize: vertical;
  min-height: 10em;
}

input[type=submit] {
  display: inline-block;
  font-size: .875rem;
  font-weight: 700;
  color: colors.$white;
  background: colors.$red;
  min-width: 10rem;
  width: fit-content;
  height: 2.75rem;
  border: none;
  @include values.defaultRadius;
  @include colors.buttonShadow;
}

button {
  display: block;
  cursor: pointer;
  background: colors.$red;
  border: none;
  color: colors.$white;
  font-size: .875rem;
  font-weight: 700;
  // min-width: 10rem;
  width: fit-content;
  height: 2.75rem;
  @include values.defaultRadius;
  @include colors.buttonShadow;
}

footer {
  background-color: colors.$black;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  .main-size {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    button {
      font-size: .75rem;
      color: white;
      background-color: colors.$black;
      border: none;
      padding: .625em 1.5em;
      margin: .5rem 0;
      @include colors.buttonShadow(colors.$black);
    }

  }

}

.page-title-text{
  font-size: 2rem;
  font-weight: 700;
  color: colors.$red;
  margin: 0;
  margin-bottom: 1rem;
}

.bold-black-text{
  font-size: 1.25rem;
  font-weight: 700;
  color: colors.$black;
  margin: 0;
}

.medium-black-text{
  font-size: 1rem;
  font-weight: 700;
  color: colors.$black;
  margin: 0;
  line-height: 26px;
}


.small-text{
  color: colors.$red;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 6px;
  line-height: 16px;
  letter-spacing: 1px;
}

.small-button{
  background-color: colors.$red;
  font-size: .90rem;
  padding: .4em .8em;
  color: colors.$white;
  font-weight: 300;
  text-align: center;
  @include colors.buttonShadow(colors.$red);
  @include values.defaultRadius;
}

.right-button-container {
  display: flex;
  justify-content: flex-end;
}

.medium-button{
  background-color: colors.$red;
  font-size: .90rem;
  padding: .5em .8em;
  color: colors.$white;
  font-weight: 300;
  text-align: center;
  @include colors.buttonShadow(colors.$red);
  @include values.defaultRadius;
}

.custom-hr {
  border: none;
  height: 1px;
  background-color:gainsboro;
  margin-bottom: 20px !important;
}

.volunteer-signups {
  margin-top: 40px;
}