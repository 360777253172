@use '../Utilities/Styling/values';
@use '../Utilities/Styling/colors';
@use '../Utilities/Styling/mixins';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  padding: 2rem;
  margin-bottom: .2rem;
  @include mixins.defaultItemContainer;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:not(:first-of-type) { margin-right: 1rem }
    &:first-of-type { flex: 30% }
    &:nth-of-type(2) { flex: 70% }
    &:last-of-type {
      flex: 0 0 1.5rem;
      align-items: center;
    }

    h5 { margin: 0 0 .25em }
  }

  button {
    background-color: colors.$red;
    color: colors.$white;
    border: none;
    cursor: pointer;
    @include mixins.smallButton;

    &:hover {
      background-color: darken(colors.$red, 10%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(colors.$red, 0.4);
    }
  }
}