:global(.map) {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  height: 400px;

  button {
    box-shadow: none;
    min-width: unset;
  }

  input {
    position: absolute;
    bottom: 1.25rem;
    left: .5rem;
    right: .5rem;
    width: calc(100% - 3.875rem);

  }
}

.addressGrid {
  row-gap: .75rem;
  :global(.h5-label) { margin: 0 }
}

.prettyAddress p:not(:last-child) { margin: 0 }
.autoComplete:disabled { display: none }

.inputGroup{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  label{
    margin: 0;

    &:first-child {
      grid-column: span 2;
    }
  }
}

.mapInput{
  position: relative;
  width: 50%;
  bottom: 70px;
  margin-left: 10px;
}