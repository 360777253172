@use '../Utilities/Styling/colors';
@use '../Utilities/Styling/mixins';
@use '../Utilities/Styling/values';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: .5rem;
  padding: 1rem;
  background-color: colors.$white;
  @include mixins.defaultItemContainer;
  font-family: values.$font-exo;

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-right: 1rem;
  }

  a {
    color: colors.$black;
    font-size: .875rem;
  }

  h2 {
    color: colors.$red;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
  }
  h5 { margin: 0 0 .25em }
  > div { width: 100% }
}

.details {
  display: flex;
  flex-direction: row;
  margin-top: .75rem;

  div {
    display: flex;
    flex-direction: column;
    flex: 1 0;
  }
}

.organizationCard{
  display: flex;
  flex-direction: column;

  h3{
    color: colors.$red;
    font-weight: 600;
    font-size: 10px;
    margin-bottom: 3px;
    line-height: 16px;
    letter-spacing: 1px;
  }

}
.organizationDetails{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: row;
  flex-grow: 1;
}

.organizationImage{
  max-width: 100px;
}