@use './Utilities/Styling/values';
@use './Utilities/Styling/colors';

nav {
  flex-direction: row;
  height: 5rem;
  @include colors.background-red-gradient;
  position: sticky;
  top: 0;

  div {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: .75rem;
    height: 100%;

    *:not(:last-child) {
      margin-right: 2rem;
    }

    a:first-child {
      height: 30px;
      width: auto;
      flex: 1;

      img {
        height: 100%;
        margin: 0 auto 0 0;
      }
    }
  }

  a {
    display: inline-block;
    color: colors.$white;
    text-decoration: none;
    letter-spacing: .05em;
    position: relative;

    &:not(:first-child)::after {
      content: '';
      position: absolute;
      bottom: -.75em;
      left: 0;
      right: 0;
      width: 0;
      height: 2px;
      background-color: colors.$white;
      opacity: 0;
      margin: 0 auto;
      transition: all 200ms ease-out;
    }

    &:not(:first-child):hover::after, &.active::after {
      width: 100%;
      opacity: 1;
    }
  }

}