// Fonts
$font-exo: 'Exo 2', sans-serif;
$font-yrsa: 'Yrsa', serif;

// Sizing
$main-max-width: 1270px;
$main-horizontal-padding: 102px;
$main-padding-bottom: 6rem;
$main-padding-top: 0rem;

@mixin mainSize {
  max-width: $main-max-width;
  width: 100%;
  margin: 0 auto;
  padding-left: $main-horizontal-padding;
  padding-right: $main-horizontal-padding;
}

// Corner Radius
@mixin defaultRadius {
  border-radius: .25rem;
}
