@use '../Utilities/Styling/values';
@use '../Utilities/Styling/colors';
@use '../Utilities/Styling/mixins';

.container {
  padding: 2.625rem;
  margin-bottom: 1rem;
  @include mixins.defaultItemContainer;

  blockquote {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.125rem;
    font-family: values.$font-yrsa;
    text-align: justify;
    font-weight: 400;
    color: colors.$black;

    &::before {
      content: '“';
      font-size: 4.5rem;
      position: absolute;
      top: -2.25rem;
      left: -1.75rem;
      color: colors.$red
    }
  }
}

.details {
  color: colors.$red;
  font-size: .875rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    @include mixins.smallButton;
  }

}