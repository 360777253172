@use '../Utilities/Styling/values';
@use '../Utilities/Styling/colors';
@use '../Utilities/Styling/mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  transition: transform 0.2s;
  @include mixins.defaultItemContainer;

  &:hover {
    transform: scale(1.02);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .title {
      flex: 1;
      h5 {
        margin: 0;
        font-size: larger;
        color: colors.$black;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .userEmail   {
        color: colors.$darkGray;
        margin-top: 0.5rem;
        font-size: larger;
      }

      .date {
        color: colors.$red;
        margin-top: 0.5rem;
        font-size: smaller;

      }
    }

    .status {
      padding: 0.3rem 0.6rem;
      font-weight: bold;
      color: colors.$white;
      text-transform: uppercase;
      border-radius: 4px;
      &.open { background-color: colors.$orange; }
      &.resolved { background-color: colors.$green; }
      &.pending { background-color: colors.$yellow; }
      &.not-fixable { background-color: colors.$red; }
    }
  }

  h6 {
    margin: 0;
    font-size: small;
    color: colors.$red;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .description {
    margin-bottom: 1rem;
    color: colors.$darkGray;
  }

  .comment{
    margin-bottom: 1rem;
    color: colors.$darkGray;
    background-color: colors.$mint;
    padding: 1rem;
    border: 1px solid colors.$gray;
    border-radius: 5px;
  }

  button {
    align-self: flex-end;
    padding: 0.5rem 1rem;
    background-color: colors.$red;
    color: colors.$white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken(colors.$blue, 10%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(colors.$blue, 0.4);
    }
  }
}

:global {
  .ant-modal-mask {
    background-color: colors.$white;
    backdrop-filter: blur(4px);
  }

  .ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal {
    top: 0;
    padding: 0;
    margin: 0;
  }

  .ant-modal-content {
    background-color: colors.$white;
    border-radius: 8px;
    border: 1px solid colors.$lightGray;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .ant-modal-header {
      border-radius: 8px 8px 0 0;
      background-color: colors.$white;
      padding: 1rem;

      .ant-modal-title {
        font-size: 1.25rem;
        font-weight: bold;
        color: colors.$black;
      }
    }

    .ant-modal-body {
      padding: 1.5rem;

      textarea {
        width: 100%;
        resize: vertical;
        min-height: 100px;
        padding: 0.5rem;
        border: 1px solid colors.$lightGray;
        border-radius: 4px;
        font-family: inherit;
        font-size: 1rem;
        color: colors.$black;

        &:focus {
          outline: none;
          border-color: colors.$blue;
          box-shadow: 0 0 0 3px rgba(colors.$blue, 0.4);
        }
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding: 1rem;
      text-align: right;

      button {
        margin-left: 0.5rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s;

        &.ant-btn-primary {
          background-color: colors.$red;
          color: colors.$white;

          &:hover {
            background-color: darken(colors.$blue, 10%);
          }
        }

        &.ant-btn-default {
          background-color: colors.$lightGray;
          color: colors.$black;

          &:hover {
            background-color: darken(colors.$lightGray, 10%);
          }
        }
      }
    }
  }
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .ant-modal {
    top: 0;
    margin: 0;
  }
}