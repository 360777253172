@use '../Utilities/Styling/colors';
@use '../Utilities/Styling/mixins';
@use '../Utilities/Styling/values';

.container {
  display: grid;
  grid-template-columns: 220px 180px 120px 1fr;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: .5rem;
  @include mixins.defaultItemContainer;

  h5 { margin: 1.5em 0 .5em; }

  img {
    width: 220px;
    height: 165px;
    @include values.defaultRadius
  }

  p {
    margin: 0;
    font-size: .75rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.dates, .volunteers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: colors.$red;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .1em;
  height: 100%;
  @include values.defaultRadius;

  div:first-child h5 { margin-top: 0 }
}

.dates { background-color: colors.$dark-pink }

.volunteers { background-color: colors.$mint }

.details {
  box-sizing: border-box;
  padding: 1rem;
  height: 100%;
}

.hosted {
  font-size: .75rem;
  margin-top: .25em;
}
