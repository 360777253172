@use 'sass:color';
@use '../../Utilities/Styling/colors';
@use '../../Utilities/Styling/values';
@use '../../Utilities/Styling/mixins';

.search {
  padding: 1.5rem;
  background-color: color.adjust(colors.$med-gray, $lightness: 3%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  div{
    flex-grow: 1;
    // height: 50px;
  }

  input{
    height: 60px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    *:not(:last-child) { margin-right: 1rem }
    input { height: 3.75rem }
    > div { flex: 1 }
  }
}

.additionalOptions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: .75rem;
}

.display-flex{
  display: flex;
}

.header{
  margin-top: 50px;
}